<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>

    <b-tabs>
      <b-tab active title="English">
        <b-card title="Add Experience Item">
          <validation-observer ref="experienceitemForm">
            <b-form>
              <b-row>
                <b-col cols="4">
                  <b-form-group label="Title" class="mb-0">
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="experience.title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Location">
                    <validation-provider
                      #default="{ errors }"
                      name="Location"
                      rules="required"
                    >
                      <b-form-input
                        v-model="experience.location.locationName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Location"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Coordinates (lat)">
                    <validation-provider
                      #default="{ errors }"
                      name="Latitude"
                      rules="required"
                    >
                      <b-form-input
                        v-model="experience.location.coordinates[0]"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Coordinates (lat)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Coordinates (lon)">
                    <validation-provider
                      #default="{ errors }"
                      name="Longitude"
                      rules="required"
                    >
                      <b-form-input
                        v-model="experience.location.coordinates[1]"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Coordinates (lon)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Amenities">
                    <v-select
                      multiple
                      label="name"
                      :options="amenities"
                      v-model="experience.amenities"
                      :reduce="
                        (amenity) => {
                          return { amenity: amenity._id };
                        }
                      "
                      placeholder="Select Amenities"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Category">
                    <v-select
                      label="title"
                      :options="experiences"
                      v-model="experience.category"
                      :reduce="
                        (experience) => {
                          return experience._id;
                        }
                      "
                      placeholder="Select Category*"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>

        <b-card title="Hotel Gallery">
          <div class="mb-1 hotel-gallery" v-if="experience.gallery.length > 0">
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in experience.gallery"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeHotelGalleryMedia(null, index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
          :params="{ lang: null}"
            :selectedMediaFiles="experience.gallery"
            @mediaSelected="handleMediaSelect"
          />
        </b-card>

        <b-card title="Banner Carousel">
          <div
            class="mb-1 hotel-gallery"
            v-if="experience.bannerCarousel.length > 0"
          >
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in experience.bannerCarousel"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeHBannerMedia(null, index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
          :params="{lang: null}"
            :selectedMediaFiles="experience.bannerCarousel"
            @mediaSelected="handleBannerSelect"
          />
        </b-card>

        <b-card title="Description">
          <b-form-group label="Excerpt">
            <b-form-textarea
              v-model="experience.excerpt"
              placeholder="Excerpt"
            />
          </b-form-group>
          <quill-editor
            v-model="experience.description"
            :options="snowOption"
          />
        </b-card>

        <b-card title="Activities">
          <b-row>
            <b-col cols="8">
              <b-form-group label="Section Title">
                <b-form-input
                  v-model="experience.activities.title"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.activities.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <p
                v-if="experience.activities.activity.length"
                class="d-block mb-1"
              >
                Activities
              </p>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateActivityItem(null)"
                  >
                    <b-row
                      v-for="(activity, index) in experience.activities
                        .activity"
                      :id="activity.id"
                      :key="activity.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Activity Name">
                          <b-form-input
                            v-model="
                              experience.activities.activity[index].title
                            "
                            type="text"
                            placeholder="Activity Name"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="mainProps"
                          v-if="
                            experience.activities.activity[index].thumbnailImg
                          "
                          :src="
                            experience.activities.activity[index].thumbnailImg
                          "
                        ></b-img>
                        <b-button
                          v-if="
                            experience.activities.activity[index].thumbnailImg
                          "
                          @click="removeActivityImage(null, index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove Image</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index, lang: null }"
                          @mediaSelected="handleActivityMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeActivityItem(null, index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>Delete</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateActivityItem(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.activities.activity.length
                      ? "Add New"
                      : "Add Activities"
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Dishes">
          <b-row>
            <b-col cols="8">
              <b-form-group label="Section Title">
                <b-form-input
                  v-model="experience.dishesSection.title"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.dishesSection.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <p
                v-if="experience.dishesSection.dishes.length"
                class="d-block mb-1"
              >
                Dishes
              </p>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateDishItem(null)"
                  >
                    <b-row
                      v-for="(dish, index) in experience.dishesSection.dishes
                        .length"
                      :id="dish.id"
                      :key="dish.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Dish Name">
                          <b-form-input
                            v-model="
                              experience.dishesSection.dishes[index].name
                            "
                            type="text"
                            placeholder="Dish Name"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="mainProps"
                          v-if="
                            experience.dishesSection.dishes[index].thumbnailImg
                          "
                          :src="
                            experience.dishesSection.dishes[index].thumbnailImg
                          "
                        ></b-img>
                        <b-button
                          v-if="
                            experience.dishesSection.dishes[index].thumbnailImg
                          "
                          @click="removeDishImage(null, index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove Image</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index , lang: null }"
                          @mediaSelected="handleDishMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeDishItem(null, index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>Delete</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateDishItem(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.activities.activity.length
                      ? "Add New"
                      : "Add Dishes"
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <!-- Guide List & how to Reach -->
        <b-row>
          <b-col>
            <b-card title="Guide List">
              <b-form-group label="Title">
                <b-form-input
                  v-model="experience.guideList.title"
                  placeholder="Title"
                />
              </b-form-group>
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.guideList.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label="Button Title">
                <b-form-input
                  v-model="experience.guideList.buttonTitle"
                  placeholder="Button Title"
                />
              </b-form-group>
              <b-form-group label="Button Link">
                <b-form-input
                  v-model="experience.guideList.buttonLink"
                  placeholder="Button Link"
                />
              </b-form-group>
            </b-card>
          </b-col>
          <b-col>
            <b-card title="How to reach">
              <b-form-group label="Title">
                <b-form-input
                  v-model="experience.howToReach.title"
                  placeholder="Location"
                />
              </b-form-group>
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.howToReach.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label="Button Title">
                <b-form-input
                  v-model="experience.howToReach.buttonTitle"
                  placeholder="Button Title"
                />
              </b-form-group>
              <b-form-group label="Button Link">
                <b-form-input
                  v-model="experience.howToReach.buttonLink"
                  placeholder="Button Link"
                />
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
        <!-- Guide List & how to Reach -->

        <b-card title="Key Pointers">
          <b-form-group label="Section Title">
            <b-form-input
              v-model="experience.keyPointer.title"
              placeholder="Section Title"
            />
          </b-form-group>
          <b-row>
            <b-col cols="12">
              <p
                v-if="experience.keyPointer.keyPoints.length"
                class="d-block mb-1"
              >
                Key Points
              </p>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateKeyPointItem(null)"
                  >
                    <b-row
                      v-for="(point, index) in experience.keyPointer.keyPoints
                        .length"
                      :id="point.id"
                      :key="point.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group
                          class="mb-0"
                          :label="`Pointer ${index + 1} Title`"
                        >
                          <b-form-input
                            type="text"
                            v-model="
                              experience.keyPointer.keyPoints[index].title
                            "
                            :placeholder="`Pointer ${index + 1} Title`"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="3" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeKeyPointItem(null, index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>Remove Section</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12" class="mt-1">
                        <b-row
                          :key="innerIndex"
                          v-for="(points, innerIndex) in experience.keyPointer
                            .keyPoints[index].points"
                        >
                          <b-col cols="5">
                            <b-form-group label="Title">
                              <b-form-input
                                v-model="
                                  experience.keyPointer.keyPoints[index].points[
                                    innerIndex
                                  ].title
                                "
                                type="text"
                                placeholder="Key Point Title"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="5" class="align-self-center">
                            <b-form-group>
                              <b-row>
                                <b-col>
                                  <ComponentMediaSelector
                                    class="mt-2"
                                    :params="{ index, innerIndex, lang: null }"
                                    buttonText="Select Icon"
                                    @mediaSelected="
                                      handleKeyPointInnerItemMediaSelect
                                    "
                                    :isMultiple="false"
                                  />
                                </b-col>
                                <b-col
                                  v-if="
                                    experience.keyPointer.keyPoints[index]
                                      .points[innerIndex].icon
                                  "
                                  ><b-avatar
                                    rounded
                                    size="60px"
                                    variant="light-secondary"
                                    :src="
                                      experience.keyPointer.keyPoints[index]
                                        .points[innerIndex].icon
                                    "
                                  ></b-avatar>
                                  <a class="ml-1" @click=" experience.keyPointer.keyPoints[index]
                                        .points[innerIndex].icon = ''"
                                    >Remove</a
                                  ></b-col
                                >
                              </b-row>
                            </b-form-group>
                          </b-col>
                          <b-col cols="2" class="align-self-center">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="
                                removeKeyPointInnerItem(index, innerIndex, null)
                              "
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                            </b-button>
                          </b-col>
                          <b-col cols="11"
                            ><b-form-group label="Description">
                              <quill-editor
                                v-model="
                                  experience.keyPointer.keyPoints[index].points[
                                    innerIndex
                                  ].description
                                "
                                :options="snowOption"
                              /> </b-form-group
                          ></b-col>
                        </b-row>
                        <b-col cols="8" class="mx-auto text-center">
                          <b-button
                            @click="repeatKeyPointInnerItem(null, index)"
                            block
                            variant="primary"
                            >Add New Pointer</b-button
                          >
                        </b-col>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateKeyPointItem(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.keyPointer.keyPoints.length
                      ? "Add New"
                      : "Add Key Points"
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <!-- What to buy and what to see -->
        <b-row>
          <b-col cols="6">
            <b-card title="What to buy">
              <b-form-group label="Tab Title">
                <b-form-input
                  v-model="experience.whatToBuy.pageTitle"
                  type="text"
                  placeholder="Tab Title"
                />
              </b-form-group>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateWhatToBuyItem(null)"
                  >
                    <b-row
                      v-for="(whatToBuy, index) in experience.whatToBuy.items
                        .length"
                      :id="whatToBuy.id"
                      :key="whatToBuy.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Title">
                          <b-form-input
                            v-model="experience.whatToBuy.items[index].title"
                            type="text"
                            placeholder="Title"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="smallProps"
                          v-if="experience.whatToBuy.items[index].thumbnailImg"
                          :src="experience.whatToBuy.items[index].thumbnailImg"
                        ></b-img>
                        <b-button
                          v-if="experience.whatToBuy.items[index].thumbnailImg"
                          @click="removeWhatToBuyImage(null, index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index, lang: null }"
                          @mediaSelected="handleWhatToBuyMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeWhatToBuyItem(null, index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateWhatToBuyItem(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.whatToBuy.length ? "Add New" : "What to buy"
                  }}</span>
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card title="What to see">
              <b-form-group label="Tab Title">
                <b-form-input
                  v-model="experience.whatToSee.pageTitle"
                  type="text"
                  placeholder="Tab Title"
                />
              </b-form-group>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateWhatToSeeItem(null)"
                  >
                    <b-row
                      v-for="(whatToBuy, index) in experience.whatToSee.items
                        .length"
                      :id="whatToBuy.id"
                      :key="whatToBuy.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Title">
                          <b-form-input
                            v-model="experience.whatToSee.items[index].title"
                            type="text"
                            placeholder="Title"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="smallProps"
                          v-if="experience.whatToSee.items[index].thumbnailImg"
                          :src="experience.whatToSee.items[index].thumbnailImg"
                        ></b-img>
                        <b-button
                          v-if="experience.whatToSee.items[index].thumbnailImg"
                          @click="removeWhatToSeeImage(null,index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index, lang: null }"
                          @mediaSelected="handleWhatToSeeMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeWhatToSeeItem(null, index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateWhatToSeeItem(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.whatToSee.length ? "Add New" : "What to see"
                  }}</span>
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <!-- What to buy and what to see -->

        <b-card title="FAQs">
          <b-form-group label="Section Title">
            <b-form-textarea
              v-model="experience.faq.title"
              placeholder="Title"
            />
          </b-form-group>
          <b-form-group label="">
            <b-form
              ref="form"
              class="repeater-form"
              @submit.prevent="repeatFAQList(null)"
            >
              <b-row
                v-for="(faq, index) in experience.faq.faqList"
                :id="faq.id"
                :key="faq.id"
                ref="row"
              >
                <b-col md="6">
                  <b-form-group class="mb-0" label="Title">
                    <b-form-input
                      v-model="experience.faq.faqList[index].title"
                      type="text"
                      placeholder="Title"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2" class="align-self-center">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeFAQList(null,index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col class="mt-1" cols="12">
                  <b-form-group class="mb-0" label="Description">
                    <ckeditor
                      v-model="experience.faq.faqList[index].description"
                    ></ckeditor>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-form>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatFAQList(null)"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{
                experience.activities.activity.length ? "Add New" : "Add FAQs"
              }}</span>
            </b-button>
          </b-form-group>
        </b-card>
        <!-- FAQ -->
      </b-tab>
      <!-- Hindi Translation -->
      <b-tab title="Hindi">
        <b-card title="Add Experience Item">
            <b-form>
              <b-row>
                <b-col cols="4">
                  <b-form-group label="Title" class="mb-0">
                      <b-form-input
                        v-model="experience.translation.hi.title"
                        placeholder="Title"
                      />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Location">
                      <b-form-input
                        v-model="
                          experience.translation.hi.location.locationName
                        "
                        placeholder="Location"
                      />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Coordinates (lat)">
                      <b-form-input
                        v-model="
                          experience.translation.hi.location.coordinates[0]
                        "
                        placeholder="Coordinates (lat)"
                      />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Coordinates (lon)">
                      <b-form-input
                        v-model="
                          experience.translation.hi.location.coordinates[1]
                        "
                        placeholder="Coordinates (lon)"
                      />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Amenities">
                    <v-select
                      multiple
                      label="name"
                      :options="amenities"
                      v-model="experience.translation.hi.amenities"
                      :reduce="
                        (amenity) => {
                          return { amenity: amenity._id };
                        }
                      "
                      placeholder="Select Amenities"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Category">
                    <v-select
                      label="title"
                      :options="experiences"
                      v-model="experience.translation.hi.category"
                      :reduce="
                        (experience) => {
                          return experience._id;
                        }
                      "
                      placeholder="Select Category*"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
        </b-card>

        <b-card title="Hotel Gallery">
          <div
            class="mb-1 hotel-gallery"
            v-if="experience.translation.hi.gallery.length > 0"
          >
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in experience.translation.hi.gallery"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeHotelGalleryMedia('hi', index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
          :params="{ lang: 'hi' }"
            :selectedMediaFiles="experience.translation.hi.gallery"
            @mediaSelected="handleMediaSelect"
          />
        </b-card>

        <b-card title="Banner Carousel">
          <div
            class="mb-1 hotel-gallery"
            v-if="experience.translation.hi.bannerCarousel.length > 0"
          >
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in experience.translation.hi.bannerCarousel"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeHBannerMedia('hi', index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
          :params="{lang: 'hi'}"
            :selectedMediaFiles="experience.translation.hi.bannerCarousel"
            @mediaSelected="handleBannerSelect"
          />
        </b-card>

        <b-card title="Description">
          <b-form-group label="Excerpt">
            <b-form-textarea
              v-model="experience.translation.hi.excerpt"
              placeholder="Excerpt"
            />
          </b-form-group>
          <quill-editor
            v-model="experience.translation.hi.description"
            :options="snowOption"
          />
        </b-card>

        <b-card title="Activities">
          <b-row>
            <b-col cols="8">
              <b-form-group label="Section Title">
                <b-form-input
                  v-model="experience.translation.hi.activities.title"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.translation.hi.activities.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <p
                v-if="experience.translation.hi.activities.activity.length"
                class="d-block mb-1"
              >
                Activities
              </p>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateActivityItem('hi')"
                  >
                    <b-row
                      v-for="(activity, index) in experience.translation.hi
                        .activities.activity"
                      :id="activity.id"
                      :key="activity.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Activity Name">
                          <b-form-input
                            v-model="
                              experience.translation.hi.activities.activity[
                                index
                              ].title
                            "
                            type="text"
                            placeholder="Activity Name"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="mainProps"
                          v-if="
                            experience.translation.hi.activities.activity[index]
                              .thumbnailImg
                          "
                          :src="
                            experience.translation.hi.activities.activity[index]
                              .thumbnailImg
                          "
                        ></b-img>
                        <b-button
                          v-if="
                            experience.translation.hi.activities.activity[index]
                              .thumbnailImg
                          "
                          @click="removeActivityImage('hi', index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove Image</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index, lang: 'hi' }"
                          @mediaSelected="handleActivityMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeActivityItem('hi', index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>Delete</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateActivityItem('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.translation.hi.activities.activity.length
                      ? "Add New"
                      : "Add Activities"
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Dishes">
          <b-row>
            <b-col cols="8">
              <b-form-group label="Section Title">
                <b-form-input
                  v-model="experience.translation.hi.dishesSection.title"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="
                        experience.translation.hi.dishesSection.description
                      "
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <p
                v-if="experience.translation.hi.dishesSection.dishes.length"
                class="d-block mb-1"
              >
                Dishes
              </p>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateDishItem('hi')"
                  >
                    <b-row
                      v-for="(dish, index) in experience.translation.hi
                        .dishesSection.dishes.length"
                      :id="dish.id"
                      :key="dish.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Dish Name">
                          <b-form-input
                            v-model="
                              experience.translation.hi.dishesSection.dishes[
                                index
                              ].name
                            "
                            type="text"
                            placeholder="Dish Name"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="mainProps"
                          v-if="
                            experience.translation.hi.dishesSection.dishes[
                              index
                            ].thumbnailImg
                          "
                          :src="
                            experience.translation.hi.dishesSection.dishes[
                              index
                            ].thumbnailImg
                          "
                        ></b-img>
                        <b-button
                          v-if="
                            experience.translation.hi.dishesSection.dishes[
                              index
                            ].thumbnailImg
                          "
                          @click="removeDishImage('hi',index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove Image</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index , lang: 'hi'}"
                          @mediaSelected="handleDishMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeDishItem('hi', index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>Delete</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateDishItem('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.translation.hi.activities.activity.length
                      ? "Add New"
                      : "Add Dishes"
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <!-- Guide List & how to Reach -->
        <b-row>
          <b-col>
            <b-card title="Guide List">
              <b-form-group label="Title">
                <b-form-input
                  v-model="experience.translation.hi.guideList.title"
                  placeholder="Title"
                />
              </b-form-group>
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.translation.hi.guideList.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label="Button Title">
                <b-form-input
                  v-model="experience.translation.hi.guideList.buttonTitle"
                  placeholder="Button Title"
                />
              </b-form-group>
              <b-form-group label="Button Link">
                <b-form-input
                  v-model="experience.translation.hi.guideList.buttonLink"
                  placeholder="Button Link"
                />
              </b-form-group>
            </b-card>
          </b-col>
          <b-col>
            <b-card title="How to reach">
              <b-form-group label="Title">
                <b-form-input
                  v-model="experience.translation.hi.howToReach.title"
                  placeholder="Location"
                />
              </b-form-group>
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.translation.hi.howToReach.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label="Button Title">
                <b-form-input
                  v-model="experience.translation.hi.howToReach.buttonTitle"
                  placeholder="Button Title"
                />
              </b-form-group>
              <b-form-group label="Button Link">
                <b-form-input
                  v-model="experience.translation.hi.howToReach.buttonLink"
                  placeholder="Button Link"
                />
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
        <!-- Guide List & how to Reach -->

        <b-card title="Key Pointers">
          <b-form-group label="Section Title">
            <b-form-input
              v-model="experience.translation.hi.keyPointer.title"
              placeholder="Section Title"
            />
          </b-form-group>
          <b-row>
            <b-col cols="12">
              <p
                v-if="experience.translation.hi.keyPointer.keyPoints.length"
                class="d-block mb-1"
              >
                Key Points
              </p>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateKeyPointItem('hi')"
                  >
                    <b-row
                      v-for="(point, index) in experience.translation.hi
                        .keyPointer.keyPoints.length"
                      :id="point.id"
                      :key="point.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group
                          class="mb-0"
                          :label="`Pointer ${index + 1} Title`"
                        >
                          <b-form-input
                            type="text"
                            v-model="
                              experience.translation.hi.keyPointer.keyPoints[
                                index
                              ].title
                            "
                            :placeholder="`Pointer ${index + 1} Title`"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="3" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeKeyPointItem('hi', index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>Remove Section</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12" class="mt-1">
                        <b-row
                          :key="innerIndex"
                          v-for="(points, innerIndex) in experience.translation
                            .hi.keyPointer.keyPoints[index].points"
                        >
                          <b-col cols="5">
                            <b-form-group label="Title">
                              <b-form-input
                                v-model="
                                  experience.translation.hi.keyPointer
                                    .keyPoints[index].points[innerIndex].title
                                "
                                type="text"
                                placeholder="Key Point Title"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="5" class="align-self-center">
                            <b-form-group>
                              <b-row>
                                <b-col>
                                  <ComponentMediaSelector
                                    class="mt-2"
                                    :params="{ index, innerIndex, lang: 'hi' }"
                                    buttonText="Select Icon"
                                    @mediaSelected="
                                      handleKeyPointInnerItemMediaSelect
                                    "
                                    :isMultiple="false"
                                  />
                                </b-col>
                                <b-col
                                  v-if="
                                    experience.translation.hi.keyPointer
                                      .keyPoints[index].points[innerIndex].icon
                                  "
                                  ><b-avatar
                                    rounded
                                    size="60px"
                                    variant="light-secondary"
                                    :src="
                                      experience.translation.hi.keyPointer
                                        .keyPoints[index].points[innerIndex]
                                        .icon
                                    "
                                  ></b-avatar>
                                  <a class="ml-1" @click="experience.translation.hi.keyPointer
                                        .keyPoints[index].points[innerIndex]
                                        .icon = ''"
                                    >Remove</a
                                  ></b-col
                                >
                              </b-row>
                            </b-form-group>
                          </b-col>
                          <b-col cols="2" class="align-self-center">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="
                                removeKeyPointInnerItem(index, innerIndex, 'hi')
                              "
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                            </b-button>
                          </b-col>
                          <b-col cols="11"
                            ><b-form-group label="Description">
                              <quill-editor
                                v-model="
                                  experience.translation.hi.keyPointer
                                    .keyPoints[index].points[innerIndex]
                                    .description
                                "
                                :options="snowOption"
                              /> </b-form-group
                          ></b-col>
                        </b-row>
                        <b-col cols="8" class="mx-auto text-center">
                          <b-button
                            @click="repeatKeyPointInnerItem('hi', index)"
                            block
                            variant="primary"
                            >Add New Pointer</b-button
                          >
                        </b-col>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateKeyPointItem('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.translation.hi.keyPointer.keyPoints.length
                      ? "Add New"
                      : "Add Key Points"
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <!-- What to buy and what to see -->
        <b-row>
          <b-col cols="6">
            <b-card title="What to buy">
              <b-form-group label="Tab Title">
                <b-form-input
                  v-model="experience.translation.hi.whatToBuy.pageTitle"
                  type="text"
                  placeholder="Tab Title"
                />
              </b-form-group>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateWhatToBuyItem('hi')"
                  >
                    <b-row
                      v-for="(whatToBuy, index) in experience.translation.hi
                        .whatToBuy.items.length"
                      :id="whatToBuy.id"
                      :key="whatToBuy.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Title">
                          <b-form-input
                            v-model="
                              experience.translation.hi.whatToBuy.items[index]
                                .title
                            "
                            type="text"
                            placeholder="Title"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="smallProps"
                          v-if="
                            experience.translation.hi.whatToBuy.items[index]
                              .thumbnailImg
                          "
                          :src="
                            experience.translation.hi.whatToBuy.items[index]
                              .thumbnailImg
                          "
                        ></b-img>
                        <b-button
                          v-if="
                            experience.translation.hi.whatToBuy.items[index]
                              .thumbnailImg
                          "
                          @click="removeWhatToBuyImage('hi',index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index, lang: 'hi' }"
                          @mediaSelected="handleWhatToBuyMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeWhatToBuyItem('hi',index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateWhatToBuyItem('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.translation.hi.whatToBuy.length
                      ? "Add New"
                      : "What to buy"
                  }}</span>
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card title="What to see">
              <b-form-group label="Tab Title">
                <b-form-input
                  v-model="experience.translation.hi.whatToSee.pageTitle"
                  type="text"
                  placeholder="Tab Title"
                />
              </b-form-group>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateWhatToSeeItem('hi')"
                  >
                    <b-row
                      v-for="(whatToBuy, index) in experience.translation.hi
                        .whatToSee.items.length"
                      :id="whatToBuy.id"
                      :key="whatToBuy.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Title">
                          <b-form-input
                            v-model="
                              experience.translation.hi.whatToSee.items[index]
                                .title
                            "
                            type="text"
                            placeholder="Title"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="smallProps"
                          v-if="
                            experience.translation.hi.whatToSee.items[index]
                              .thumbnailImg
                          "
                          :src="
                            experience.translation.hi.whatToSee.items[index]
                              .thumbnailImg
                          "
                        ></b-img>
                        <b-button
                          v-if="
                            experience.translation.hi.whatToSee.items[index]
                              .thumbnailImg
                          "
                          @click="removeWhatToSeeImage('hi',index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index , lang: 'hi'}"
                          @mediaSelected="handleWhatToSeeMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeWhatToSeeItem('hi',index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateWhatToSeeItem('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.translation.hi.whatToSee.length
                      ? "Add New"
                      : "What to see"
                  }}</span>
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <!-- What to buy and what to see -->

        <b-card title="FAQs">
          <b-form-group label="Section Title">
            <b-form-textarea
              v-model="experience.translation.hi.faq.title"
              placeholder="Title"
            />
          </b-form-group>
          <b-form-group label="">
            <b-form
              ref="form"
              class="repeater-form"
              @submit.prevent="repeatFAQList('hi')"
            >
              <b-row
                v-for="(faq, index) in experience.translation.hi.faq.faqList"
                :id="faq.id"
                :key="faq.id"
                ref="row"
              >
                <b-col md="6">
                  <b-form-group class="mb-0" label="Title">
                    <b-form-input
                      v-model="
                        experience.translation.hi.faq.faqList[index].title
                      "
                      type="text"
                      placeholder="Title"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2" class="align-self-center">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeFAQList('hi',index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col class="mt-1" cols="12">
                  <b-form-group class="mb-0" label="Description">
                    <ckeditor
                      v-model="
                        experience.translation.hi.faq.faqList[index].description
                      "
                    ></ckeditor>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-form>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatFAQList('hi')"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{
                experience.translation.hi.activities.activity.length
                  ? "Add New"
                  : "Add FAQs"
              }}</span>
            </b-button>
          </b-form-group>
        </b-card>
        <!-- FAQ -->
      </b-tab>
      <!-- Marathi Translation -->
      <b-tab title="Marathi">
        <b-card title="Add Experience Item">
            <b-form>
              <b-row>
                <b-col cols="4">
                  <b-form-group label="Title" class="mb-0">
                      <b-form-input
                        v-model="experience.translation.mr.title"
                        placeholder="Title"
                      />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Location">
                      <b-form-input
                        v-model="experience.translation.mr.location.locationName"
                        placeholder="Location"
                      />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Coordinates (lat)">
                      <b-form-input
                        v-model="experience.translation.mr.location.coordinates[0]"
                        placeholder="Coordinates (lat)"
                      />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Coordinates (lon)">
                      <b-form-input
                        v-model="experience.translation.mr.location.coordinates[1]"
                        placeholder="Coordinates (lon)"
                      />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Amenities">
                    <v-select
                      multiple
                      label="name"
                      :options="amenities"
                      v-model="experience.translation.mr.amenities"
                      :reduce="
                        (amenity) => {
                          return { amenity: amenity._id };
                        }
                      "
                      placeholder="Select Amenities"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Category">
                    <v-select
                      label="title"
                      :options="experiences"
                      v-model="experience.translation.mr.category"
                      :reduce="
                        (experience) => {
                          return experience._id;
                        }
                      "
                      placeholder="Select Category*"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
        </b-card>

        <b-card title="Hotel Gallery">
          <div class="mb-1 hotel-gallery" v-if="experience.translation.mr.gallery.length > 0">
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in experience.translation.mr.gallery"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeHotelGalleryMedia('mr', index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
          :params="{ lang: 'mr'}"
            :selectedMediaFiles="experience.translation.mr.gallery"
            @mediaSelected="handleMediaSelect"
          />
        </b-card>

        <b-card title="Banner Carousel">
          <div
            class="mb-1 hotel-gallery"
            v-if="experience.translation.mr.bannerCarousel.length > 0"
          >
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in experience.translation.mr.bannerCarousel"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeHBannerMedia('mr', index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
          :params="{lang: 'mr'}"
            :selectedMediaFiles="experience.translation.mr.bannerCarousel"
            @mediaSelected="handleBannerSelect"
          />
        </b-card>

        <b-card title="Description">
          <b-form-group label="Excerpt">
            <b-form-textarea
              v-model="experience.translation.mr.excerpt"
              placeholder="Excerpt"
            />
          </b-form-group>
          <quill-editor
            v-model="experience.translation.mr.description"
            :options="snowOption"
          />
        </b-card>

        <b-card title="Activities">
          <b-row>
            <b-col cols="8">
              <b-form-group label="Section Title">
                <b-form-input
                  v-model="experience.translation.mr.activities.title"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.translation.mr.activities.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <p
                v-if="experience.translation.mr.activities.activity.length"
                class="d-block mb-1"
              >
                Activities
              </p>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateActivityItem('mr')"
                  >
                    <b-row
                      v-for="(activity, index) in experience.translation.mr.activities
                        .activity"
                      :id="activity.id"
                      :key="activity.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Activity Name">
                          <b-form-input
                            v-model="
                              experience.translation.mr.activities.activity[index].title
                            "
                            type="text"
                            placeholder="Activity Name"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="mainProps"
                          v-if="
                            experience.translation.mr.activities.activity[index].thumbnailImg
                          "
                          :src="
                            experience.translation.mr.activities.activity[index].thumbnailImg
                          "
                        ></b-img>
                        <b-button
                          v-if="
                            experience.translation.mr.activities.activity[index].thumbnailImg
                          "
                          @click="removeActivityImage('mr', index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove Image</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index , lang: 'mr'}"
                          @mediaSelected="handleActivityMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeActivityItem('mr', index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>Delete</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateActivityItem('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.translation.mr.activities.activity.length
                      ? "Add New"
                      : "Add Activities"
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Dishes">
          <b-row>
            <b-col cols="8">
              <b-form-group label="Section Title">
                <b-form-input
                  v-model="experience.translation.mr.dishesSection.title"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.translation.mr.dishesSection.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <p
                v-if="experience.translation.mr.dishesSection.dishes.length"
                class="d-block mb-1"
              >
                Dishes
              </p>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateDishItem('mr')"
                  >
                    <b-row
                      v-for="(dish, index) in experience.translation.mr.dishesSection.dishes
                        .length"
                      :id="dish.id"
                      :key="dish.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Dish Name">
                          <b-form-input
                            v-model="
                              experience.translation.mr.dishesSection.dishes[index].name
                            "
                            type="text"
                            placeholder="Dish Name"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="mainProps"
                          v-if="
                            experience.translation.mr.dishesSection.dishes[index].thumbnailImg
                          "
                          :src="
                            experience.translation.mr.dishesSection.dishes[index].thumbnailImg
                          "
                        ></b-img>
                        <b-button
                          v-if="
                            experience.translation.mr.dishesSection.dishes[index].thumbnailImg
                          "
                          @click="removeDishImage('mr',index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove Image</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index, lang: 'mr' }"
                          @mediaSelected="handleDishMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeDishItem('mr', index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>Delete</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateDishItem('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.translation.mr.activities.activity.length
                      ? "Add New"
                      : "Add Dishes"
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <!-- Guide List & how to Reach -->
        <b-row>
          <b-col>
            <b-card title="Guide List">
              <b-form-group label="Title">
                <b-form-input
                  v-model="experience.translation.mr.guideList.title"
                  placeholder="Title"
                />
              </b-form-group>
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.translation.mr.guideList.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label="Button Title">
                <b-form-input
                  v-model="experience.translation.mr.guideList.buttonTitle"
                  placeholder="Button Title"
                />
              </b-form-group>
              <b-form-group label="Button Link">
                <b-form-input
                  v-model="experience.translation.mr.guideList.buttonLink"
                  placeholder="Button Link"
                />
              </b-form-group>
            </b-card>
          </b-col>
          <b-col>
            <b-card title="How to reach">
              <b-form-group label="Title">
                <b-form-input
                  v-model="experience.translation.mr.howToReach.title"
                  placeholder="Location"
                />
              </b-form-group>
              <b-form-group label="Description">
                <b-row>
                  <b-col cols="12">
                    <quill-editor
                      v-model="experience.translation.mr.howToReach.description"
                      :options="snowOption"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label="Button Title">
                <b-form-input
                  v-model="experience.translation.mr.howToReach.buttonTitle"
                  placeholder="Button Title"
                />
              </b-form-group>
              <b-form-group label="Button Link">
                <b-form-input
                  v-model="experience.translation.mr.howToReach.buttonLink"
                  placeholder="Button Link"
                />
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
        <!-- Guide List & how to Reach -->

        <b-card title="Key Pointers">
          <b-form-group label="Section Title">
            <b-form-input
              v-model="experience.translation.mr.keyPointer.title"
              placeholder="Section Title"
            />
          </b-form-group>
          <b-row>
            <b-col cols="12">
              <p
                v-if="experience.translation.mr.keyPointer.keyPoints.length"
                class="d-block mb-1"
              >
                Key Points
              </p>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateKeyPointItem('mr')"
                  >
                    <b-row
                      v-for="(point, index) in experience.translation.mr.keyPointer.keyPoints
                        .length"
                      :id="point.id"
                      :key="point.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group
                          class="mb-0"
                          :label="`Pointer ${index + 1} Title`"
                        >
                          <b-form-input
                            type="text"
                            v-model="
                              experience.translation.mr.keyPointer.keyPoints[index].title
                            "
                            :placeholder="`Pointer ${index + 1} Title`"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="3" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeKeyPointItem('mr', index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>Remove Section</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12" class="mt-1">
                        <b-row
                          :key="innerIndex"
                          v-for="(points, innerIndex) in experience.translation.mr.keyPointer
                            .keyPoints[index].points"
                        >
                          <b-col cols="5">
                            <b-form-group label="Title">
                              <b-form-input
                                v-model="
                                  experience.translation.mr.keyPointer.keyPoints[index].points[
                                    innerIndex
                                  ].title
                                "
                                type="text"
                                placeholder="Key Point Title"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="5" class="align-self-center">
                            <b-form-group>
                              <b-row>
                                <b-col>
                                  <ComponentMediaSelector
                                    class="mt-2"
                                    :params="{ index, innerIndex, lang: 'mr' }"
                                    buttonText="Select Icon"
                                    @mediaSelected="
                                      handleKeyPointInnerItemMediaSelect
                                    "
                                    :isMultiple="false"
                                  />
                                </b-col>
                                <b-col
                                  v-if="
                                    experience.translation.mr.keyPointer.keyPoints[index]
                                      .points[innerIndex].icon
                                  "
                                  ><b-avatar
                                    rounded
                                    size="60px"
                                    variant="light-secondary"
                                    :src="
                                      experience.translation.mr.keyPointer.keyPoints[index]
                                        .points[innerIndex].icon
                                    "
                                  ></b-avatar>
                                  <a class="ml-1" @click="experience.translation.mr.keyPointer.keyPoints[index]
                                        .points[innerIndex].icon = ''"
                                    >Remove</a
                                  ></b-col
                                >
                              </b-row>
                            </b-form-group>
                          </b-col>
                          <b-col cols="2" class="align-self-center">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="
                                removeKeyPointInnerItem(index, innerIndex, 'mr')
                              "
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                            </b-button>
                          </b-col>
                          <b-col cols="11"
                            ><b-form-group label="Description">
                              <quill-editor
                                v-model="
                                  experience.translation.mr.keyPointer.keyPoints[index].points[
                                    innerIndex
                                  ].description
                                "
                                :options="snowOption"
                              /> </b-form-group
                          ></b-col>
                        </b-row>
                        <b-col cols="8" class="mx-auto text-center">
                          <b-button
                            @click="repeatKeyPointInnerItem('mr', index)"
                            block
                            variant="primary"
                            >Add New Pointer</b-button
                          >
                        </b-col>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateKeyPointItem('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.translation.mr.keyPointer.keyPoints.length
                      ? "Add New"
                      : "Add Key Points"
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <!-- What to buy and what to see -->
        <b-row>
          <b-col cols="6">
            <b-card title="What to buy">
              <b-form-group label="Tab Title">
                <b-form-input
                  v-model="experience.translation.mr.whatToBuy.pageTitle"
                  type="text"
                  placeholder="Tab Title"
                />
              </b-form-group>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateWhatToBuyItem('mr')"
                  >
                    <b-row
                      v-for="(whatToBuy, index) in experience.translation.mr.whatToBuy.items
                        .length"
                      :id="whatToBuy.id"
                      :key="whatToBuy.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Title">
                          <b-form-input
                            v-model="experience.translation.mr.whatToBuy.items[index].title"
                            type="text"
                            placeholder="Title"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="smallProps"
                          v-if="experience.translation.mr.whatToBuy.items[index].thumbnailImg"
                          :src="experience.translation.mr.whatToBuy.items[index].thumbnailImg"
                        ></b-img>
                        <b-button
                          v-if="experience.translation.mr.whatToBuy.items[index].thumbnailImg"
                          @click="removeWhatToBuyImage('mr',index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index, lang: 'mr' }"
                          @mediaSelected="handleWhatToBuyMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeWhatToBuyItem('mr',index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateWhatToBuyItem('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.translation.mr.whatToBuy.length ? "Add New" : "What to buy"
                  }}</span>
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card title="What to see">
              <b-form-group label="Tab Title">
                <b-form-input
                  v-model="experience.translation.mr.whatToSee.pageTitle"
                  type="text"
                  placeholder="Tab Title"
                />
              </b-form-group>
              <div>
                <div>
                  <b-form
                    ref="form"
                    class="repeater-form"
                    @submit.prevent="repeateWhatToSeeItem('mr')"
                  >
                    <b-row
                      v-for="(whatToBuy, index) in experience.translation.mr.whatToSee.items
                        .length"
                      :id="whatToBuy.id"
                      :key="whatToBuy.id"
                      ref="row"
                    >
                      <b-col md="6">
                        <b-form-group class="mb-0" label="Title">
                          <b-form-input
                            v-model="experience.translation.mr.whatToSee.items[index].title"
                            type="text"
                            placeholder="Title"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col class="align-self-center text-center" cols="4">
                        <b-img
                          class="mb-1 mx-auto"
                          v-bind="smallProps"
                          v-if="experience.translation.mr.whatToSee.items[index].thumbnailImg"
                          :src="experience.translation.mr.whatToSee.items[index].thumbnailImg"
                        ></b-img>
                        <b-button
                          v-if="experience.translation.mr.whatToSee.items[index].thumbnailImg"
                          @click="removeWhatToSeeImage('mr',index)"
                          variant="flat-danger"
                          class="ml-1"
                          link
                          >Remove</b-button
                        >
                        <ComponentMediaSelector
                          :params="{ index, lang: 'mr' }"
                          @mediaSelected="handleWhatToSeeMediaSelect"
                          :isMultiple="false"
                        />
                      </b-col>

                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeWhatToSeeItem('mr',index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateWhatToSeeItem('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    experience.translation.mr.whatToSee.length ? "Add New" : "What to see"
                  }}</span>
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <!-- What to buy and what to see -->

        <b-card title="FAQs">
          <b-form-group label="Section Title">
            <b-form-textarea
              v-model="experience.translation.mr.faq.title"
              placeholder="Title"
            />
          </b-form-group>
          <b-form-group label="">
            <b-form
              ref="form"
              class="repeater-form"
              @submit.prevent="repeatFAQList('mr')"
            >
              <b-row
                v-for="(faq, index) in experience.translation.mr.faq.faqList"
                :id="faq.id"
                :key="faq.id"
                ref="row"
              >
                <b-col md="6">
                  <b-form-group class="mb-0" label="Title">
                    <b-form-input
                      v-model="experience.translation.mr.faq.faqList[index].title"
                      type="text"
                      placeholder="Title"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2" class="align-self-center">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeFAQList('mr',index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col class="mt-1" cols="12">
                  <b-form-group class="mb-0" label="Description">
                    <ckeditor
                      v-model="experience.translation.mr.faq.faqList[index].description"
                    ></ckeditor>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-form>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatFAQList('mr')"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{
                experience.translation.mr.activities.activity.length ? "Add New" : "Add FAQs"
              }}</span>
            </b-button>
          </b-form-group>
        </b-card>
        <!-- FAQ -->
      </b-tab>
    </b-tabs>

    <b-card>
      <b-row>
        <b-col>
          <b-button @click="saveDraft" size="lg" variant="outline-info" block
            >Save Draft</b-button
          >
        </b-col>
        <b-col>
          <b-button
            @click="createExperienceItem"
            size="lg"
            variant="primary"
            block
            >Create Experience</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import { required } from "@validations";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BTabs,
  BTab,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BImg,
  BButton,
  BAvatar,
  BAlert,
} from "bootstrap-vue";
import axios from "axios";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";
import { getUserToken } from "@/auth/utils";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    vSelect,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    quillEditor,
    ComponentMediaSelector,
    BImg,
    BButton,
    BAvatar,
  },
  data() {
    return {
      required,
      mainProps: {
        width: 100,
        height: 100,
      },
      smallProps: {
        width: 40,
        height: 40,
      },
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      amenities: [],
      experiences: [],
      experience: {
        title: "",
        location: {
          locationName: "",
          coordinates: [],
        },
        description: "",
        excerpt: "",
        gallery: [],
        bannerCarousel: [],
        amenities: [],
        activities: {
          title: "",
          description: "",
          activity: [],
        },
        dishesSection: {
          title: "",
          description: "",
          dishes: [],
        },
        category: "",
        guideList: {
          title: "",
          description: "",
          buttonTitle: "",
          buttonLink: "",
        },
        howToReach: {
          title: "",
          description: "",
          buttonTitle: "",
          buttonLink: "",
        },
        faq: {
          title: "",
          faqList: [],
        },
        keyPointer: {
          title: "",
          keyPoints: [],
        },
        whatToBuy: {
          pageTitle: "",
          items: [],
        },
        whatToSee: {
          pageTitle: "",
          items: [],
        },
        translation: {
          hi: {
            title: "",
            location: {
              locationName: "",
              coordinates: [],
            },
            description: "",
            excerpt: "",
            gallery: [],
            bannerCarousel: [],
            amenities: [],
            activities: {
              title: "",
              description: "",
              activity: [],
            },
            dishesSection: {
              title: "",
              description: "",
              dishes: [],
            },
            category: null,
            guideList: {
              title: "",
              description: "",
              buttonTitle: "",
              buttonLink: "",
            },
            howToReach: {
              title: "",
              description: "",
              buttonTitle: "",
              buttonLink: "",
            },
            faq: {
              title: "",
              faqList: [],
            },
            keyPointer: {
              title: "",
              keyPoints: [],
            },
            whatToBuy: {
              pageTitle: "",
              items: [],
            },
            whatToSee: {
              pageTitle: "",
              items: [],
            },
          },
          mr: {
            title: "",
            location: {
              locationName: "",
              coordinates: [],
            },
            description: "",
            excerpt: "",
            gallery: [],
            bannerCarousel: [],
            amenities: [],
            activities: {
              title: "",
              description: "",
              activity: [],
            },
            dishesSection: {
              title: "",
              description: "",
              dishes: [],
            },
            category: null,
            guideList: {
              title: "",
              description: "",
              buttonTitle: "",
              buttonLink: "",
            },
            howToReach: {
              title: "",
              description: "",
              buttonTitle: "",
              buttonLink: "",
            },
            faq: {
              title: "",
              faqList: [],
            },
            keyPointer: {
              title: "",
              keyPoints: [],
            },
            whatToBuy: {
              pageTitle: "",
              items: [],
            },
            whatToSee: {
              pageTitle: "",
              items: [],
            },
          },
        },
      },
      isDraftAvailable: false,
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    repeatFAQList(lang=undefined) {
      if(lang) {
this.experience.translation[lang].faq.faqList.push({
        title: "",
        description: "",
      });
      } else {
      this.experience.faq.faqList.push({
        title: "",
        description: "",
      });
      }
    },
    removeFAQList(lang=undefined, index) {
      if(lang) {
      this.experience.translation[lang].faq.faqList.splice(index, 1);
      } else {
      this.experience.faq.faqList.splice(index, 1);
      }
    },
    getHotelAmenities() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/amenity/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.amenities = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Hotel Themes Data");
        });
    },
    createExperienceItem() {
      this.$refs.experienceitemForm.validate().then((success) => {
        if (success) {
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/hotel/experienceItem/create`,
              this.experience,
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.removeDraft();
              this.makeToast(
                "success",
                "Success",
                `${this.experience.title} has been created successfully!`
              );
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast(
                "danger",
                "Error",
                "Error creating the experience."
              );
            });
        } else {
          this.makeToast(
            "danger",
            "Error",
            "Please fix the errors to continue."
          );
        }
      });
    },
    saveDraft() {
      localStorage.setItem("experience-item", JSON.stringify(this.experience));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const experienceItem = localStorage.getItem("experience-item");
      this.experience = JSON.parse(experienceItem);
    },
    removeDraft() {
      localStorage.removeItem("experience-item");
      this.isDraftAvailable = false;
    },
    getExperiences() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/experienceItem/all?type=mh`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.experiences = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    handleMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.experience.translation[mediaObject.params.lang].gallery = mediaObject.media;
        } else {
        this.experience.gallery = mediaObject.media;

        }
      }
    },
    handleBannerSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.experience.translation[mediaObject.params.lang].bannerCarousel = mediaObject.media;
        } else{
        this.experience.bannerCarousel = mediaObject.media;
        }
      }
    },
    removeHotelGalleryMedia(lang=undefined, index) {
      if(lang) {
      this.experience.translation[lang].gallery.splice(index, 1);
      } else {
      this.experience.gallery.splice(index, 1);

      }
    },
    repeateActivityItem(lang=undefined) {
      if(lang) {
        this.experience.translation[lang].activities.activity.push({
        id: this.experience.translation[lang].activities.activity.length + 1,
        title: "",
        thumbnailImg: "",
      });
      } else {
        this.experience.activities.activity.push({
        id: this.experience.activities.activity.length + 1,
        title: "",
        thumbnailImg: "",
      });
      }
      
    },
    repeateDishItem(lang=undefined) {
      if(lang) {
        this.experience.translation[lang].dishesSection.dishes.push({
        id: this.experience.translation[lang].dishesSection.dishes.length + 1,
        name: "",
        thumbnailImg: "",
      });
      } else {
        this.experience.dishesSection.dishes.push({
        id: this.experience.dishesSection.dishes.length + 1,
        name: "",
        thumbnailImg: "",
      });
      }
      
    },
    repeateWhatToBuyItem(lang=undefined) {
      if(lang) {
this.experience.translation[lang].whatToBuy.items.push({
        title: "",
        thumbnailImg: "",
      });
      } else {
        this.experience.whatToBuy.items.push({
        title: "",
        thumbnailImg: "",
      });
      }
      
    },
    repeateWhatToSeeItem(lang=undefined) {
      if(lang) {
this.experience.translation[lang].whatToSee.items.push({
        title: "",
        thumbnailImg: "",
      });
      } else {
this.experience.whatToSee.items.push({
        title: "",
        thumbnailImg: "",
      });
      }
      
    },
    removeWhatToBuyItem(lang=undefined, index) {
      if(lang) {
      this.experience.translation[lang].whatToBuy.items.splice(index, 1);
      } else {
      this.experience.whatToBuy.items.splice(index, 1);
      }
    },
    removeWhatToBuyImage(lang=undefined, index) {
      if(lang) {
      this.experience.translation[lang].whatToBuy.items[index].thumbnailImg = "";
      } else {
      this.experience.whatToBuy.items[index].thumbnailImg = "";
      }
    },
    removeWhatToSeeImage(lang=undefined, index) {
      if(lang) {
      this.experience.translation[lang].whatToSee.items[index].thumbnailImg = "";
      } else {
        this.experience.whatToSee.items[index].thumbnailImg = "";
      }
    },
    removeWhatToSeeItem(lang=undefined, index) {
      if(lang) {
      this.experience.translation[lang].whatToSee.items.splice(index, 1);
      } else {
      this.experience.whatToSee.items.splice(index, 1);
      }
    },
    removeHBannerMedia(lang=undefined, index) {
      if(lang) {
        this.experience.translation[lang].bannerCarousel.splice(index, 1);
      }
      else {
       this.experience.bannerCarousel.splice(index, 1);
      }
    },
    handleActivityMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
          this.experience.translation[mediaObject.params.lang].activities.activity[
          mediaObject.params.index
        ].thumbnailImg = mediaObject.media.src;
        } else {
          this.experience.activities.activity[
          mediaObject.params.index
        ].thumbnailImg = mediaObject.media.src;
        }
      }
    },
    handleDishMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
 this.experience.translation[mediaObject.params.lang].dishesSection.dishes[
          mediaObject.params.index
        ].thumbnailImg = mediaObject.media.src;
        } else {
           this.experience.dishesSection.dishes[
          mediaObject.params.index
        ].thumbnailImg = mediaObject.media.src;
        }
       
      }
    },
    handleWhatToBuyMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.experience.translation[mediaObject.params.lang].whatToBuy.items[mediaObject.params.index].thumbnailImg =
          mediaObject.media.src;  
        } else {
          this.experience.whatToBuy.items[mediaObject.params.index].thumbnailImg =
          mediaObject.media.src;
        }
      }
    },
    handleWhatToSeeMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.experience.translation[mediaObject.params.lang].whatToSee.items[mediaObject.params.index].thumbnailImg =
          mediaObject.media.src;
        } else {
        this.experience.whatToSee.items[mediaObject.params.index].thumbnailImg =
          mediaObject.media.src;
        }
      }
    },
    removeActivityItem(lang= undefined, index) {
      if(lang) {
      this.experience.translation[lang].activities.activity.splice(index, 1);
      } else {
      this.experience.activities.activity.splice(index, 1);
      }
    },
    removeActivityImage(lang=undefined, index) {
      if(lang) {
      this.experience.translation[lang].activities.activity[index].thumbnailImg = "";
      } else {
      this.experience.activities.activity[index].thumbnailImg = "";
      }
    },
    removeDishItem(lang=undefined, index) {
      if(lang){

      this.experience.translation[lang].dishesSection.dishes.splice(index, 1);
      } else {
      this.experience.dishesSection.dishes.splice(index, 1);
      }
    },
    removeDishImage(lang=undefined, index) {
       if(lang) {
      this.experience.translation[lang].dishesSection.dishes[index].thumbnailImg = "";
      } else {
      this.experience.dishesSection.dishes[index].thumbnailImg = "";
      }
    },
    repeateKeyPointItem(lang=undefined) {
      if(lang) {
this.experience.translation[lang].keyPointer.keyPoints.push({
        id: this.experience.translation[lang].keyPointer.keyPoints.length + 1,
        title: "",
        points: [],
      });
      }
      else {
        this.experience.keyPointer.keyPoints.push({
        id: this.experience.keyPointer.keyPoints.length + 1,
        title: "",
        points: [],
      });
      }
    },
    removeKeyPointItem(lang=undefined, index) {
      if(lang) {
      this.experience.translation[lang].keyPointer.keyPoints.splice(index, 1);
      } else {
      this.experience.keyPointer.keyPoints.splice(index, 1);
      }
    },
    repeatKeyPointInnerItem(lang=undefined, index) {
      if(lang) {
this.experience.translation[lang].keyPointer.keyPoints[index].points.push({
        id: this.experience.translation[lang].keyPointer.keyPoints[index].points + 1,
        title: "",
        description: "",
        icon: "",
      });
      } else {
        this.experience.keyPointer.keyPoints[index].points.push({
        id: this.experience.keyPointer.keyPoints[index].points + 1,
        title: "",
        description: "",
        icon: "",
      });
      }
      
    },
    removeKeyPointInnerItem(index, innerIndex, lang=undefined) {
      if(lang) {
      this.experience.translation[lang].keyPointer.keyPoints[index].points.splice(innerIndex, 1);
      } else{
      this.experience.keyPointer.keyPoints[index].points.splice(innerIndex, 1);

      }
    },
    handleKeyPointInnerItemMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
this.experience.translation[mediaObject.params.lang].keyPointer.keyPoints[mediaObject.params.index].points[
          mediaObject.params.innerIndex
        ].icon = mediaObject.media.src;
        } else{
          this.experience.keyPointer.keyPoints[mediaObject.params.index].points[
          mediaObject.params.innerIndex
        ].icon = mediaObject.media.src;
        }
        
      }
    },
  },
  mounted() {
    this.getHotelAmenities();
    this.getExperiences();
    if (localStorage.getItem("experience-item")) {
      this.isDraftAvailable = true;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.hotel-gallery {
  display: flex;
  .gallery-item {
    position: relative;

    .actions {
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }
}
</style>